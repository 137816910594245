import React from "react"
import Footer from "./footer";
import Header from "./header";

import "../style.scss"

const Layout = ({ isHomePage, children }) => {

  if (typeof window !== 'undefined') {
    const isFlag = document.querySelectorAll('body > noscript')
    if (isFlag.length == 0) {
      const para = document.createElement("noscript");
      const node = document.createTextNode(`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K84XVBZ" height="0" width="0" style="display:none;visibility:hidden"></iframe>`);
    
      para.appendChild(node);
      document.body.appendChild(para);
    }
  }

  return (
    <div data-is-root-path={isHomePage}>

      <Header />

      <main>{children}</main>

      <Footer />
    </div>
  )
}

Layout.defaultProps = {
  isHomePage: false,
}


export default Layout
