import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper'
import CheckSeoForm from "../components/forms/check-seo-form"
// import FeedbackForm from "../components/forms/feedback-form"
import Layout from "../components/layout"
import Seo from "../components/seo"

import "../css/@index/style.scss"
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'


const HomePage = () => {

  const [viewMore, setViewMore] = useState(false)

  const handleViewMore = () => {
    setViewMore(!viewMore)
  }

  return (
    <Layout>
      <Seo />
      <div className="homepage">
        <div className="homepage__banner">
          <StaticImage 
            src="../images/image-banner.png"
            alt="Toponseek"
            className="wrapper-header__logo__image"
            // width={1440}
            height={197}
          />
          <p className="homepage__banner--title">
            Google Search Demand:
            <br />
            VIETNAM BANKING REVIEW AND OUTLOOK 2022
          </p>
          <div className="homepage__banner--divide"></div>
        </div>
        <div className="homepage__wrapper-content">
          <div className="homepage__wrapper-content__contain">
            <div className="homepage__wrapper-content__contain__box-content">
              <div className="homepage__wrapper--content">
                <div className={`homepage__content--custom--height ${viewMore ? "active" : ''}`}>
                  <p>
                    Due to the great impact of the covid-19 pandemic on the financial market in general and the banking industry in particular, the information-seeking behavior and the use of financial services of people have been changing.
                    <br />
                    <br />
                    With the aim to provide the bank industry with an overview of the information that people are searching and a forecast of search demand in the period from May 2022 to May 2023, TOS has compiled that into the report: GOOGLE SEARCH DEMAND: VIETNAM BANKING REVIEW AND OUTLOOK 2022. This report is based on more than 12.005 keywords in our database relating to the banking industry. Below are what you’ll learn:
                  </p>
                  <ul>
                    <li>Growth in the Google search demand the banking industry and search demand by each category of the banking industry</li>
                    <li>Share of voice of some typical categories</li>
                    <li>Search trend forecast for the "Loan", "Foreign Exchange", and "E-Banking” categories.</li>
                  </ul>
                </div>
                <p className="homepage__content--view--more" onClick={() => handleViewMore()}>
                  {
                    viewMore ?
                    'View Less'
                    : 'View More'
                  }
                  {viewMore ? <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_72_62)">
                      <path fillRule="evenodd" clipRule="evenodd" d="M12.2077 5.66996L7.94414 1.10734C7.16366 0.297571 5.89748 0.297571 5.11699 1.10734L0.79247 5.7321C0.406724 6.13284 0.401711 6.78003 0.78246 7.18595C1.1712 7.60016 1.81179 7.60533 2.20653 7.19734L5.82354 3.30366C6.21428 2.89878 6.84686 2.89878 7.2376 3.30366L10.7946 7.1342C11.1844 7.53908 11.8169 7.53908 12.2077 7.1342C12.5974 6.72931 12.5974 6.07381 12.2077 5.66996Z" fill="#00B9AD"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_72_62">
                        <rect width="12" height="7" fill="white" transform="translate(12.5 7.5) rotate(-180)"/>
                      </clipPath>
                    </defs>
                  </svg> : <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_72_36)">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0.792308 2.33004L5.05586 6.89266C5.83634 7.70243 7.10252 7.70243 7.88301 6.89266L12.2075 2.2679C12.5933 1.86716 12.5983 1.21997 12.2175 0.814046C11.8288 0.399842 11.1882 0.394672 10.7935 0.802663L7.17646 4.69634C6.78572 5.10122 6.15314 5.10122 5.7624 4.69634L2.20539 0.865804C1.81565 0.46092 1.18305 0.46092 0.792308 0.865804C0.402564 1.27069 0.402564 1.92619 0.792308 2.33004Z" fill="#00B9AD"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_72_36">
                          <rect width="12" height="7" fill="white" transform="translate(0.5 0.5)"/>
                        </clipPath>
                      </defs>
                    </svg>
                  }
                </p>
              </div>
              <div className="homepage__wrapper--slide--wrapper">
                <div className="homepage__wrapper--slide">
                  <Swiper
                    modules={[Navigation, Pagination]}
                    slidesPerView={1}
                    navigation
                    pagination={true}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    <SwiperSlide>
                      <StaticImage 
                        src="../images/Report-Banking-1.png"
                        alt="Toponseek"
                        className="content-image"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <StaticImage 
                        src="../images/Report-Banking-3.png"
                        alt="Toponseek"
                        className="content-image"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <StaticImage 
                        src="../images/Report-Banking-9.png"
                        alt="Toponseek"
                        className="content-image"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <StaticImage 
                        src="../images/Report-Banking-15.png"
                        alt="Toponseek"
                        className="content-image"
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <StaticImage 
                        src="../images/Report-Banking-19.png"
                        alt="Toponseek"
                        className="content-image"
                      />
                    </SwiperSlide>
                  </Swiper>
                </div>
                <div className="homepage__wrapper-content__contain__box-content__wrapper-download">
                  <p>
                    <span>View full report here:</span>
                    <span>* If you view on mobile device, please rotate your mobile horizontally</span>
                  </p>
                  <a href="https://profile.toponseek.com/#1" id='viewReportLP' target={"_blank"} >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M14.274 6.673L14.271 6.66901C13.896 6.26601 13.265 6.244 12.863 6.62L11.842 7.577C11.522 7.876 11 7.64901 11 7.21201V1C11 0.447 10.552 0 10 0C9.448 0 9 0.447 9 1V7.19699C9 7.63699 8.47303 7.86201 8.15503 7.55801L7.18103 6.63C6.78203 6.248 6.148 6.26601 5.771 6.66901C5.397 7.06801 5.41603 7.69501 5.81403 8.07101L8.66199 10.759C9.43599 11.49 10.646 11.487 11.415 10.753L14.231 8.06699C14.624 7.69199 14.643 7.071 14.274 6.673ZM20 18.222C20 19.2 19.32 20 17.888 20H1.77802C0.796015 20 0 19.204 0 18.222V11.777C0 10.847 0.707 10 1.888 10H3.00702C3.55502 10 4 10.444 4 10.993V11.006C4 11.555 3.55502 12 3.00702 12H2.99298C2.44498 12 2 12.444 2 12.993V17.006C2 17.555 2.44498 18 2.99298 18H17.007C17.555 18 18 17.555 18 17.006V12.993C18 12.444 17.555 12 17.007 12H16.993C16.445 12 16 11.555 16 11.006V10.993C16 10.444 16.445 10 16.993 10H18.222C19.204 10 20 10.796 20 11.777V18.222Z" fill="white"/>
                    </svg>
                    <span>View Report</span>
                  </a>
                </div>
              </div>
            </div>
            <div className="homepage__wrapper-content__contain__box-form">
              <CheckSeoForm />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default HomePage