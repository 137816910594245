import React, { useState } from "react";
import FreeForm from "./free-form";
import QuotationForm from "./quotation-form";

const CheckSeoForm = () => {

  const [typeForm, setTypeForm] = useState("Check");

  const handleChange = () => {

  }

  return (
    <div className="container-form-seo">
      <div className="container-form-seo__header">
        <div className={`container-form-seo__header__item ${typeForm == "Check" ? "active" : ""}`} onClick={() => setTypeForm("Check")}>
          <p>Feedback</p>
        </div>
        <div className={`container-form-seo__header__item ${typeForm == "Quotations" ? "active" : ""}`} onClick={() => setTypeForm("Quotations")}>
          <p>Free SEO Audit</p>
        </div>
      </div>
      <div className="container-form-seo__body">
        {
          typeForm == "Check" ?
            <FreeForm />
          :
            <QuotationForm />
        }
      </div>
    </div>
  )
}

export default CheckSeoForm;