import React, { useState } from "react";
import Swal from 'sweetalert2'

import 'sweetalert2/src/sweetalert2.scss'

const FreeForm = () => {

  const [state, setState] = useState({
    name: "",
    email: "",
    phone: "",
    feedback: ""
  })

  const [error, setError] = useState({
    name: "",
    email: "",
    phone: "",
    feedback: ""
  })

  const [isSubmiting, setIsSubmiting] = useState(false)

  function handleChange(e) {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value
    });
    setError({
      ...error,
      [e.target.name]: ""
    });
  }

  const handleSubmitForm = async () => {
      
    const isSubmit = await validateForm();

    if(isSubmit) {

      setIsSubmiting(true);

      const data = {
        yourName: state.name,
        yourPhone: state.phone,
        workEmail: state.email,
        yourFeedBack: state.feedback
      }
      
      fetch(`${process.env.GATSBY_SITE_API}/api/feedback/feedback-insert`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then(response => response.json())
      .then(data => {
        Swal.fire({
          title: 'Success!',
          text: 'We will contact you shortly!',
          icon: 'success',
          confirmButtonText: 'OK'
        })
        setIsSubmiting(false);
        setState({
          name: "",
          email: "",
          phone: "",
          feedback: ""
        })
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    }

  }

  const validateForm = () => {

    let errorName = "";
    let errorEmail = "";
    let errorPhone = "";
    let errorFeedback = "";

    function validateEmail(email) {
      const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
      return regex.test(email);
    }

    function validatePhone(phone) {
      const regex = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g
      return regex.test(phone);
    }

    if(state.name) {
      errorName = "";
    } else {
      errorName = "Name is required";
    }

    if(state.phone) {
      if(validatePhone(`0${state.phone}`)) {
        errorPhone = "";
      } else {
        errorPhone = "Phone is invalid";
      }
    } else {
      errorPhone = "Phone is required";
    }

    if(state.email) {
      if(validateEmail(state.email)) {
        errorEmail = "";
      } else {
        errorEmail = "Email is invalid";
      }
    } else {
      errorEmail = "Email is required";
    }

    if(state.feedback) {
      errorFeedback = "";
    } else {
      errorFeedback = "Feedback is required";
    }

    if(errorName || errorEmail || errorPhone || errorFeedback) {
      setError({
        name: errorName,
        email: errorEmail,
        phone: errorPhone,
        feedback: errorFeedback,
      })
      return false;
    } else {
      return true;
    }
  }

  return (
    <div className="free-form-container">
      <p>Your feedbacks are valuable for us to improve on future reports. If you would like to have the similar report for your industry, please leave your message here.</p>
      <div className="form-free__form-detail">
        <div className="form-group">
          <label 
            className="form-label" 
            htmlFor="name"
          >
            Your Name <span>(*)</span>
          </label>
          <input 
            type="text" 
            className="form-control" 
            id="name"
            name="name"
            value={state.name}
            onChange={handleChange}
            placeholder='Full name'
          />
          {error.name && <span className="error">{error.name}</span>}
        </div>
        <div className="form-group">
          <label 
            className="form-label" 
            htmlFor="phone"
          >
            Your Phone <span>(*)</span>
          </label>
          <input 
            type="number" 
            className="form-control" 
            id="phone"
            name="phone"
            value={state.phone}
            onChange={handleChange}
            placeholder='Your Phone Number'
          />
          {error.phone && <span className="error">{error.phone}</span>}
        </div>
        {/* <div className="form-group">
          <label 
            className="form-label" 
            htmlFor="domain"
          >
            Your Domain <span>*</span>
          </label>
          <input 
            type="text" 
            className="form-control" 
            id="domain"
            name="domain"
            value={state.domain}
            onChange={handleChange}
          />
          {error.domain && <span className="error">{error.domain}</span>}
        </div> */}
        <div className="form-group">
          <label 
            className="form-label" 
            htmlFor="email"
          >
            Work Email <span>(*)</span>
          </label>
          <input 
            type="text" 
            className="form-control" 
            id="email"
            name="email"
            value={state.email}
            onChange={handleChange}
            placeholder='Your Working Email '
          />
          {error.email && <span className="error">{error.email}</span>}
        </div>
        {/* <div className="form-group">
          <label 
            className="form-label" 
            htmlFor="companyName"
          >
            Company Name <span>*</span>
          </label>
          <input 
            type="text" 
            className="form-control" 
            id="companyName"
            name="companyName"
            value={state.companyName}
            onChange={handleChange}
          />
          {error.companyName && <span className="error">{error.companyName}</span>}
        </div>
        <div className="form-group">
          <label 
            className="form-label" 
            htmlFor="companySize"
          >
            Company Size <span>*</span>
          </label>
          <div className="form-select">
            <select value={state.companySize} name="companySize" id="companySize" className="cutom-select" onChange={handleChange}>
              <option value="-1" selected hidden></option>
              <option value="1">Less than 10</option>
              <option value="2">11 - 50</option>
              <option value="3">51 - 200</option>
              <option value="4">201 - 500</option>
              <option value="5">Over 500</option>
            </select>
            <svg width="19" height="9" viewBox="0 0 19 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="0.312348" y1="0.609566" x2="10.3123" y2="8.60957" stroke="black"/>
              <line x1="9.66782" y1="8.6263" x2="18.6678" y2="0.626295" stroke="black"/>
            </svg>
          </div>
          {error.companySize && <span className="error">{error.companySize}</span>}
        </div>
        <div className="form-group">
          <label 
            className="form-label" 
            htmlFor="typeBusiness"
          >
            Type of Business Activities <span>*</span>
          </label>
          <div className="form-select">
            <select value={state.typeBusiness} name="typeBusiness" id="typeBusiness" className="cutom-select" onChange={handleChange}>
              <option value="-1" selected hidden></option>
              <option value="1">E-Commerce</option>
              <option value="2">Transport</option>
              <option value="3">Manufacturing</option>
              <option value="4">Media, Games and Entertainment</option>
              <option value="5">Service</option>
              <option value="6">Tourism and Travel</option>
            </select>
            <svg width="19" height="9" viewBox="0 0 19 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="0.312348" y1="0.609566" x2="10.3123" y2="8.60957" stroke="black"/>
              <line x1="9.66782" y1="8.6263" x2="18.6678" y2="0.626295" stroke="black"/>
            </svg>
          </div>
          {error.typeBusiness && <span className="error">{error.typeBusiness}</span>}
        </div>
        <div className="form-group">
          <label 
            className="form-label" 
            htmlFor="seoTeam"
          >
            Do you have in-house SEO team? <span>*</span>
          </label>
          <div className="form-select">
            <select value={state.seoTeam} name="seoTeam" id="seoTeam" className="cutom-select" onChange={handleChange}>
              <option value="-1" selected hidden></option>
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
            <svg width="19" height="9" viewBox="0 0 19 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="0.312348" y1="0.609566" x2="10.3123" y2="8.60957" stroke="black"/>
              <line x1="9.66782" y1="8.6263" x2="18.6678" y2="0.626295" stroke="black"/>
            </svg>
          </div>
          {error.seoTeam && <span className="error">{error.seoTeam}</span>}
        </div>
        <div className="form-group">
          <label 
            className="form-label" 
            htmlFor="problem"
          >
            What is your current problem with your website? <span>*</span>
          </label>
          <input 
            type="text" 
            className="form-control" 
            id="problem"
            name="problem"
            value={state.problem}
            onChange={handleChange}
          />
          {error.problem && <span className="error">{error.problem}</span>}
        </div>
        <div className="form-group">
          <label 
            className="form-label" 
            htmlFor="useSeo"
          >
            Do you use any SEO tools? <span>*</span>
          </label>
          <input 
            type="text" 
            className="form-control" 
            id="useSeo"
            name="useSeo"
            value={state.useSeo}
            onChange={handleChange}
          />
          {error.useSeo && <span className="error">{error.useSeo}</span>}
        </div>
        <div className="form-group">
          <label 
            className="form-label" 
            htmlFor="whyKnow"
          >
            Why do you know us? <span>*</span>
          </label>
          <input 
            type="text" 
            className="form-control" 
            id="whyKnow"
            name="whyKnow"
            value={state.whyKnow}
            onChange={handleChange}
          />
          {error.whyKnow && <span className="error">{error.whyKnow}</span>}
        </div> */}
        <div className="form-group">
          <label 
            className="form-label" 
            htmlFor="feedback"
          >
            Your Feedback <span>(*)</span>
          </label>
          <textarea 
            className="form-control" 
            id="feedback"
            name="feedback"
            value={state.feedback}
            onChange={handleChange}
            placeholder='Share your feedback or if you would like to have the similar report for your industry'
          />
          {error.feedback && <span className="error">{error.feedback}</span>}
        </div>
        <div className="wrapper-button">
          <button disabled={isSubmiting} className="feedback-submit" type="button" onClick={() => handleSubmitForm()}>{isSubmiting ? "Loading..." : "Submit"}</button>
        </div>
      </div>
    </div>
  )
}

export default FreeForm;