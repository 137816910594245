import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useShowConsole } from "../hooks"

const Seo = ({ 
  lang, 
  metaTitle,
  metaDescription,
  canonical,
  image,
}) => {

  // useShowConsole();

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
    >
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />

      <link rel="canonical" href={canonical}></link>

      <meta property="og:type" content="website" />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:url" content={canonical} />
      <meta property="og:image" content={image} />

      <script src="https://kit.fontawesome.com/6074c1e0b6.js"></script>

      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-85370365-1"></script>

      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'UA-85370365-1');
        `}
      </script>

      <script>
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-K84XVBZ');
        `}
      </script>

      <noscript>
        {`
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K84XVBZ" height="0" width="0" style="display:none;visibility:hidden"></iframe>
        `}
      </noscript>

      <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@type": "LocalBusiness",
          "image": "${process.env.GATSBY_SITE_URL}/favicon.png",
          "name": "${process.env.GATSBY_SITE_NAME}",
          "url": "${process.env.GATSBY_SITE_URL}",
          "telephone": "${process.env.GATSBY_SITE_TELEPHONE}",
          "priceRange": "$$$",
          "address": {
              "@type": "PostalAddress",
              "streetAddress": "${process.env.GATSBY_SITE_ADDRESS}",
              "addressRegion": "HCM",
              "addressLocality": "Hồ Chí Minh",
              "postalCode": "700000",
              "addressCountry": "VN"
          },
          "geo": {
            "@type": "GeoCoordinates",
            "latitude": 10.762317,
            "longitude": 106.7010493
              
          },
          "openingHoursSpecification": [{
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday"
              ],
              "opens": "08:00",
              "closes": "20:30"
          },{
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": "Saturday",
              "opens": "08:00",
              "closes": "12:00"
          }],
          "sameAs": [
            "https://www.linkedin.com/company/toponseek/",
            "https://twitter.com/TopOnSeek",
            "https://github.com/hieund-seoexpert",
            "https://www.facebook.com/TopOnSeekCom/"
            ]
          }
        }
      `}</script>

      <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@type": "Organization",
          "name": "TopOnSeek",
          "url": "https://www.toponseek.com/",
          "logo": "https://www.toponseek.com/hieund-profile/",
          "contactPoint": [{
              "@type": "ContactPoint",
              "telephone": "+84-938-678-642",
              "contactType": "customer support",
              "contactOption": "TollFree",
              "areaServed": "VN",
              "availableLanguage": "Vietnamese"
          },{
              "@type": "ContactPoint",
              "telephone": "+84-938-678-642",
              "contactType": "sales",
              "contactOption": "TollFree",
              "areaServed": "VN",
              "availableLanguage": "Vietnamese"
          }],
          "sameAs": [
          "https://www.linkedin.com/company/toponseek/",
          "https://twitter.com/TopOnSeek",
          "https://github.com/hieund-seoexpert",
          "https://www.facebook.com/TopOnSeekCom/"
          ]
        }
      `}</script>

      <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@type": "Person",
          "name": "Hieu Nguyen",
          "jobTitle": "SEO Expert",
          "image" : "https://www.toponseek.com/hieund-profile/",
          "worksFor" : "TopOnSeek",
          "url": "https://www.toponseek.com/",
          "sameAs":[
              "https://www.facebook.com/hieu.nguyenduc",
              "https://twitter.com/TopOnSeek",
              "https://github.com/hieund-seoexpert",
              "https://www.linkedin.com/in/hieunguyenduc/"
          ],
          "AlumniOf": [ 
              "University of Economics and Law",
              "Management Information Systems"
          ],
          "address": {
              "@type": "PostalAddress",
              "addressLocality": "Vi&#7879;t Nam",
              "addressRegion": "H&#7891; Ch� Minh"
          }
        }
      `}</script>

    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  metaTitle: process.env.GATSBY_SITE_TITLE,
  metaDescription: process.env.GATSBY_SITE_DESCRIPTION,
  canonical: process.env.GATSBY_SITE_URL,
  image: `${process.env.GATSBY_SITE_URL}/favicon.png`,
}

Seo.propTypes = {
  lang: PropTypes.string,
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  canonical: PropTypes.string,
  image: PropTypes.string,
}

export default Seo
