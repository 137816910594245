import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import "./style.scss";

const Footer = () => {

  return (
    <footer>
      <div 
        className="wrapper-footer"
      >
        <div className="wrapper-footer__container">
          <div className="wrapper-footer__item">
            <ul>
              <li>
                <p className="wrapper-footer__title">Address</p>
              </li>
              <li>
                <p className="adress-group">
                  <span>
                    <svg width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M7.44217 21.393C7.90723 21.8877 8.35243 22.3613 8.76247 22.8118C9.28375 22.2518 9.83018 21.688 10.3846 21.116C13.8807 17.5087 17.6935 13.5747 17.5134 8.21573C17.0182 3.59289 13.2892 0 8.76247 0C4.23575 0 0.506704 3.59289 0.0115782 8.21573C-0.254038 13.2064 4.10536 17.8436 7.44217 21.393ZM8.67947 12.411C10.6525 12.411 12.252 10.7284 12.252 8.65273C12.252 6.5771 10.6525 4.89447 8.67947 4.89447C6.70641 4.89447 5.10693 6.5771 5.10693 8.65273C5.10693 10.7284 6.70641 12.411 8.67947 12.411Z" fill="#00B9AD"/>
                    </svg>
                  </span>
                  <span>
                    <strong>Ho Chi Minh:</strong>
                    <br />
                    <a href="https://www.google.com/maps/place/TopOnSeek+-+D%E1%BB%8Bch+v%E1%BB%A5+SEO/@10.762317,106.703238,17z/data=!3m1!4b1!4m5!3m4!1s0x3175298de975aba5:0xbee8594628efabac!8m2!3d10.762317!4d106.703238" target={"_blank"}>
                      3rd Floor, Nam Phuong Building, 68 Hoang Dieu, Ward 12, District 4
                    </a>
                  </span>
                </p>
              </li>
              <li>
                <p className="adress-group">
                  <span>
                    <svg width="18" height="23" viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M7.44217 21.393C7.90723 21.8877 8.35243 22.3613 8.76247 22.8118C9.28375 22.2518 9.83018 21.688 10.3846 21.116C13.8807 17.5087 17.6935 13.5747 17.5134 8.21573C17.0182 3.59289 13.2892 0 8.76247 0C4.23575 0 0.506704 3.59289 0.0115782 8.21573C-0.254038 13.2064 4.10536 17.8436 7.44217 21.393ZM8.67947 12.411C10.6525 12.411 12.252 10.7284 12.252 8.65273C12.252 6.5771 10.6525 4.89447 8.67947 4.89447C6.70641 4.89447 5.10693 6.5771 5.10693 8.65273C5.10693 10.7284 6.70641 12.411 8.67947 12.411Z" fill="#00B9AD"/>
                    </svg>
                  </span>
                  <span>
                    <strong>Da Nang:</strong> 
                    <br />
                    <a href="https://www.google.com/maps/place/TopOnSeek+-+D%E1%BB%8Bch+v%E1%BB%A5+SEO+t%E1%BA%A1i+%C4%90%C3%A0+N%E1%BA%B5ng/@16.0745542,108.2239668,17z/data=!3m1!4b1!4m5!3m4!1s0x31421976b0e86f8f:0x90bfb1ccfda667da!8m2!3d16.0745542!4d108.2239668" target={"_blank"}>
                      3rd floor, DNES - Da Nang Business Incubator, 31 Tran Phu, Hai Chau, Da Nang
                    </a>
                  </span>
                </p>
              </li>
            </ul>
          </div>
          <div className="wrapper-footer__item">
            <ul>
              <li>
                <p className="wrapper-footer__title">Contact</p>
              </li>
              <li>
                <p className="contact-group">
                  <span>
                    <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7.91658 8.99919C9.47941 13.2626 11.0973 14.9636 15.2133 16.7755L13.4296 19.2806C8.70312 17.0178 6.34897 13.2034 5.24024 10.2267L7.91658 8.99919Z" fill="#00B9AD"/>
                      <path d="M9.22814 3.1132C10.0638 3.10993 10.6992 3.8053 10.6423 4.5927C10.5526 5.8318 10.6481 6.72579 10.9051 7.83393C11.0462 8.44263 10.7269 9.06085 10.2193 9.45905C9.53529 9.99577 9.01268 10.6796 8.59733 11.2684C8.02163 12.0843 6.04043 12.0257 5.60228 11.1368C4.70183 9.30989 4.09159 5.94412 5.40651 3.67014C5.61306 3.31296 6.02893 3.12571 6.45986 3.12403L9.22814 3.1132Z" fill="#00B9AD"/>
                      <path d="M21.913 16.6324C22.017 15.8493 21.3624 15.1699 20.5279 15.1286C19.2146 15.0637 18.2863 14.8667 17.1524 14.4925C16.5295 14.2869 15.8412 14.512 15.3616 14.9399C14.7151 15.5167 13.9334 15.9244 13.2646 16.2431C12.3376 16.6847 12.161 18.5492 13.0427 19.0669C14.8548 20.1308 18.3195 21.1077 20.868 20.1484C21.2683 19.9977 21.5151 19.6303 21.5687 19.2265L21.913 16.6324Z" fill="#00B9AD"/>
                    </svg>
                  </span>
                  <span>
                    <a href="tel:02873022558">02873022558</a>
                  </span>
                </p>
              </li>
              <li>
                <p className="contact-group">
                  <span>
                    <svg width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M21.7982 0C22.5173 0 23.1559 0.331375 23.5577 0.84393L11.8876 10.252L0.321426 1.01477C0.708144 0.406204 1.40564 0 2.20183 0H21.7982ZM0 15.6614V2.6842L8.29903 9.31217L0.0171078 15.9265C0.00581906 15.8396 0 15.7512 0 15.6614ZM0.758997 17.2601C1.14542 17.5826 1.64991 17.7778 2.20183 17.7778H21.7982C22.2725 17.7778 22.7119 17.6336 23.0713 17.3883L14.2526 10.279L12.6668 11.5574C12.2183 11.919 11.5649 11.9204 11.1147 11.5609L9.50491 10.2752L0.758997 17.2601ZM15.4519 9.31217L23.9398 16.1549C23.9792 15.9965 24 15.8313 24 15.6614V2.42096L15.4519 9.31217Z" fill="#00B9AD"/>
                    </svg>
                  </span>
                  <span>
                    <a href="mailto:long.bui@toponseek.com">long.bui@toponseek.com</a>
                  </span>
                </p>
              </li>
              <li>
                <div className="contact-group-social">
                  <a href="https://www.facebook.com/TopOnSeekCom" target={"_blank"}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16Z" fill="#1877F2"/>
                      <path d="M24 16C24 11.6 20.4 8 16 8C11.6 8 8 11.6 8 16C8 20 10.9 23.3 14.7 23.9V18.3H12.7V16H14.7V14.2C14.7 12.2 15.9 11.1 17.7 11.1C18.6 11.1 19.5 11.3 19.5 11.3V13.3H18.5C17.5 13.3 17.2 13.9 17.2 14.5V16H19.4L19 18.3H17.1V24C21.1 23.4 24 20 24 16Z" fill="white"/>
                    </svg>
                  </a>
                  <a href="https://www.linkedin.com/company/toponseek/mycompany/" target={"_blank"}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16Z" fill="#2867B2"/>
                      <path d="M11.6 24H8.2V13.3H11.6V24ZM9.9 11.8C8.8 11.8 8 11 8 9.9C8 8.8 8.9 8 9.9 8C11 8 11.8 8.8 11.8 9.9C11.8 11 11 11.8 9.9 11.8ZM24 24H20.6V18.2C20.6 16.5 19.9 16 18.9 16C17.9 16 16.9 16.8 16.9 18.3V24H13.5V13.3H16.7V14.8C17 14.1 18.2 13 19.9 13C21.8 13 23.8 14.1 23.8 17.4V24H24Z" fill="white"/>
                    </svg>
                  </a>
                  <a href="https://www.youtube.com/channel/UCE0A3jBSTyqI4jSL7SrK-gw" target={"_blank"}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16Z" fill="#FF0000"/>
                      <path d="M23.6 12.1C23.4 11.4 22.9 10.9 22.2 10.7C21 10.4 15.9 10.4 15.9 10.4C15.9 10.4 10.9 10.4 9.60001 10.7C8.90001 10.9 8.4 11.4 8.2 12.1C8 13.4 8 16 8 16C8 16 8 18.6 8.3 19.9C8.5 20.6 9 21.1 9.7 21.3C10.9 21.6 16 21.6 16 21.6C16 21.6 21 21.6 22.3 21.3C23 21.1 23.5 20.6 23.7 19.9C24 18.6 24 16 24 16C24 16 24 13.4 23.6 12.1ZM14.4 18.4V13.6L18.6 16L14.4 18.4Z" fill="white"/>
                    </svg>
                  </a>
                  <a href="https://www.pinterest.com/toponseek/_created/" target={"_blank"}>
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16Z" fill="#E60023"/>
                      <path d="M16 8C11.6 8 8 11.6 8 16C8 19.3 10 22.1 12.8 23.3C12.8 22.7 12.8 22.1 12.9 21.5C13.1 20.8 13.9 17.1 13.9 17.1C13.9 17.1 13.6 16.6 13.6 15.8C13.6 14.6 14.3 13.7 15.1 13.7C15.8 13.7 16.2 14.2 16.2 14.9C16.2 15.6 15.7 16.7 15.5 17.7C15.3 18.5 15.9 19.2 16.8 19.2C18.3 19.2 19.3 17.3 19.3 14.9C19.3 13.1 18.1 11.8 16 11.8C13.6 11.8 12.1 13.6 12.1 15.6C12.1 16.3 12.3 16.8 12.6 17.2C12.7 17.4 12.8 17.4 12.7 17.6C12.7 17.7 12.6 18.1 12.5 18.2C12.4 18.4 12.3 18.5 12.1 18.4C11 17.9 10.5 16.7 10.5 15.3C10.5 13 12.4 10.3 16.2 10.3C19.3 10.3 21.3 12.5 21.3 14.9C21.3 18 19.6 20.4 17 20.4C16.1 20.4 15.3 19.9 15 19.4C15 19.4 14.5 21.2 14.4 21.6C14.2 22.2 13.9 22.8 13.6 23.3C14.3 23.5 15.1 23.6 15.9 23.6C20.3 23.6 23.9 20 23.9 15.6C24 11.6 20.4 8 16 8Z" fill="white"/>
                    </svg>
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div className="wrapper-footer__item">
            <ul>
              <li>
                <StaticImage
                  src="../../../images/footer-image.png"
                  alt="Toponseek"
                  className="footer__item__image"
                />
              </li>
              <li>
                <p>Business registration number 0313898409 issued by the Department of Planning and Investment of Ho Chi Minh City on July 6, 2016.</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer